<template>
    <q-page padding :class="`q-pa-none bg-text${ lightMode ? '' : '-dark'} text-${ lightMode ? 'primary' : 'secondary'}`" style="height: calc( 100vh ); overflow-x: hidden; overflow-y: auto; width: 100%;">
        <div class="q-pa-none" style="height: calc( 65vh - 30px ); min-height: 300px;">
            <q-carousel
                infinite
                animated
                v-model="slide"
                height="100%"
                :autoplay="autoplay"
                @mouseenter="autoplay = false"
                @mouseleave="autoplay = buffer"
                @transition="resetBuffer"
                @before-transition="beforeResetBuffer"
            >
                <q-carousel-slide id="img" v-for="(photo, index) in bannerPhotos" :key="`${photo.id}`" :name="index" style="height: 100%; width: 100%; padding: 0px !important; overflow: hidden;">
                    <div class="background-image justify-center" :style="`background-image: url(${photo.image_url}); height: calc( 100% + 220px ); width: calc( 100vw + 220px ); background-size: cover; position: absolute; filter: blur(75px); margin: -110px -110px -110px -110px; background-position: 50% 50%; background-color: ${ lightMode ? '#E1E5F2' : '#474747'};`">
                        
                    </div>
                    <div class="foreground-image flex justify-center" style="height: calc( 65vh - 200px ); width: calc( 65vh - 200px ); margin-top: 30px;">
                        <q-img v-if="! checkIsVideo(photo.animation_url)" :ratio="1" img-class="top-image-card" :style="`border-radius: 10px; background-color: transparent !important;`" :src="photo.image_url" fit="contain">
                            <template v-slot:error>
                                <div class="absolute-full flex column flex-center text-warning non-selectable" :style="`background-color: ${lightMode ? '' : '#FFF'} !important;`">
                                    <q-icon size="48px" class="text-warning" name="error"/>
                                    <div>Error Loading Image</div>
                                </div>
                            </template>
                        </q-img>
                        <video v-else :img-class="`bg-card-primary${lightMode ? '-dark' : ''}`" style="border-radius: 10px; object-fit: cover; height: 100%; z-index: 5;" :src="photo.animation_url" autoplay loop muted :controls="false"/>
                    </div>
                    <!-- <div class="foreground-image" :style="`background-image: url(${photo.animation_url || photo.image_url}); height: calc( 80% - 30px ); width: 100%; background-size: contain; background-repeat: no-repeat; position: absolute; background-position: 50% 50%; margin-top: 30px; top: 10%;`">
                        
                    </div> -->
                    <!-- <div class="absolute-bottom custom-caption">
                        <div class="text-h2">First stop</div>
                        <div class="text-subtitle1">Mountains</div>
                    </div> -->
                </q-carousel-slide>
            </q-carousel>
            <q-linear-progress animation-speed="1500" :value="currentBuffer" :buffer="1" color="warning" style="margin-top: -4px;" />
        </div>
        <div class="q-px-md flex column justify-begin align-center" style="width: 100%;">
            <div class="flex row">
                <div class="q-px-sm q-pt-sm text-h6 self-center">NFT Collection:</div>
                <q-space/>
                <div class="q-px-sm q-pt-sm text-overline self-center">{{minCount - chunkSize}} - {{minCount}}/{{transactionsTotalCount}} NFT's</div>
            </div>
            <div class="`q-px-sm" style="width: 100%;">
                <q-carousel
                    v-model="collectionSlide"
                    transition-prev="slide-right"
                    transition-next="slide-left"
                    swipeable
                    animated
                    :control-color="lightMode ? 'primary' : 'secondary'"
                    arrows
                    height="100%"
                    style="width: 100%;"
                    ref="sliderRef"
                    id="collection-slide"
                >
                    <q-carousel-slide :class="`bg-text${ lightMode ? '' : '-dark'}`" v-for="(transactions, index) in chunkedTransactions" :key="`${index}`" :name="index">
                        <div :class="`q-px-xl flex row no-wrap q-gutter-sm justify-center align-center bg-text${ lightMode ? '' : '-dark'}`" style="padding-top: 0px; padding-bottom: 0px;">
                            <div v-for="(transaction, index) in transactions" :key="`${transactionsIndex || 0}-${index}`" style="flex justify-center">
                                <DataCard :dark="! lightMode" :data="transaction" :onlyCard="true" :width="`${cardSize}px`" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"></DataCard>
                            </div>
                        </div>
                    </q-carousel-slide>
                </q-carousel>
            </div>
        </div>
        <div class="q-px-xl justify-center align-center" v-if="false">
            <div class="q-pa-sm text-h6 text-primary">For Sale:</div>
            <q-page-container class="q-pa-sm text-h6 text-primary">
                <!-- <DataCard></DataCard> -->
            </q-page-container>
        </div>
        <!-- <div style="height: 100px; width: 110vw; margin-top: -50px; margin-left: -5vw; filter: blur(30px); background-color: rgba(255, 255, 255, 0.7);"></div> -->
        <FocusedNFTDialog v-if="showFocusedNFT" :frontImage="focusedFrontImage" :backImage="focusedBackImage"></FocusedNFTDialog>
    </q-page>
</template>

<script>
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from '../router/index';
import DataCard from '../components/DataCard';
import FocusedNFTDialog from '../components/FocusedNFTDialog';

export default {
  name: 'HomeView',
  props: {
  },
  setup () {
    const store = useStore(),
        router = useRouter(),
        slide = ref(0),
        collectionSlideWidth = ref(),
        collectionSlide = ref(0),
        autoplay = ref(15000),
        buffer = ref(14000),
        currentBuffer = ref(1),
        bufferInterval = ref(),
        animationSpeed = ref(1800),
        imgColor = ref(''),
        minCount = computed(() => {
            return Math.min(displayedNFTCount.value,transactionsTotalCount.value);
        }),
        lightMode = computed(() => {
            return store.getters["configStore/getLightMode"]
        }),
        transactionsIndex = computed(() => store.getters['transactionsStore/getTransactionsIndex']),
        bannerPhotos = computed(() => {
            let bannerPhotos = [];

            transactions.value.forEach((transaction) => {
                featuredPhotos.value.forEach(featured => {
                    if ( featured.nft_id === `${transaction.symbol}-${transaction.tokenID}`) {
                        bannerPhotos.push(featured);
                    }
                })
            })

            if ( ! bannerPhotos.length ) {
                transactions.value.forEach((transaction) => {
                    bannerPhotos.push({
                        id: `${transaction.symbol}-${transaction.tokenID}`,
                        animation_url: transaction.images.animationUrl,
                        image_url: transaction.images.url,
                        preview_url: transaction.images.previewUrl
                    })
                })
            }
            
            return bannerPhotos;
        }),
        showFocusedNFT = computed(() => store.getters['focusedNFTStore/getIsShowing']),
        focusedFrontImage = computed(() => store.getters['focusedNFTStore/getFrontImage']),
        focusedBackImage = computed(() => store.getters['focusedNFTStore/getBackImage']),
        featuredPhotos = computed(() => store.getters['transactionsStore/getFeatured']),
        transactions = computed(() => store.getters['transactionsStore/getTransactions']),
        transactionsTotalCount = computed(() => store.getters['transactionsStore/getTotalCount']),
        displayedNFTCount = computed(() => {
            return (collectionSlide.value + 1) * chunkSize.value
        }),
        cardSize = computed(() => {
            let cardSize = 250,
                collectionWidth = collectionSlideWidth.value - 50;

            if ( collectionWidth > 1500 ) {
                cardSize = collectionWidth / 7;
            }
            else if ( collectionWidth > 1250 ) {
                cardSize = collectionWidth / 6;
            }
            else if ( collectionWidth > 1000 ) {
                cardSize = collectionWidth / 5;
            }
            else if ( collectionWidth > 750 ) {
                cardSize = collectionWidth / 4;
            }
            else if ( collectionWidth > 500 ) {
                cardSize = collectionWidth / 3;
            }
            else if ( collectionWidth > 250 ) {
                cardSize = collectionWidth / 2;
            }
            else if ( collectionWidth > 0 ) {
                cardSize = collectionWidth;
            }

            return cardSize;
        }),
        chunkSize = computed(() => {
            let chunks = Math.floor( collectionSlideWidth.value / cardSize.value );
            
            return chunks > 0 ? Math.min( chunks, transactionsTotalCount.value ) : 1;
        }),
        chunkedTransactions = computed(() => {
            const perChunk = chunkSize.value;

            return transactions.value.reduce((resultArray, item, index) => { 
                const chunkIndex = Math.floor(index/perChunk)

                if(!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = [] // start a new chunk
                }

                resultArray[chunkIndex].push(item)

                return resultArray
            }, [])
        });

    const beforeResetBuffer = () => {
        autoplay.value = 15000;
        buffer.value = 14000;
        animationSpeed.value = 100;
    }
    
    const resetBuffer = () => {
        animationSpeed.value = 1800;
    }

    const checkIsVideo = (string) => {
        if (string) {
            return string.includes(".mp4");
        }
        return false
    }

    const debounce = (func, timeP) => {
        let time = timeP || 100,
            timer;

        return function(event){
            if(timer) clearTimeout(timer);
            timer = setTimeout(func, time, event);
        };
    }
    
    const getCollectionWidth = () => {
      let collectionSlideEl = document.getElementById('collection-slide');
      
      if ( collectionSlideEl ) {
        collectionSlideWidth.value = collectionSlideEl.clientWidth - 120;
      }

      store.commit('transactionsStore/bumpTransactionsIndex');

      if ( collectionSlide.value > chunkedTransactions.value.length ) {
        collectionSlide.value = chunkedTransactions.value.length - 1;
      }
    }

    onMounted( async () => {
      let collectionSlide = document.getElementById('collection-slide');
      
      if ( collectionSlide ) {
        collectionSlideWidth.value = collectionSlide.clientWidth - 120;
      }

      window.addEventListener("resize", debounce( getCollectionWidth, 250 ));
    
      await store.dispatch('transactionsStore/getFeatured')
      await store.dispatch('transactionsStore/getTransactions', {address: router.currentRoute.value.query.wallet})
      await store.dispatch('transactionsStore/getTransactionsTotalCount', {address: router.currentRoute.value.query.wallet})

      if ( ! bufferInterval.value ) {
        bufferInterval.value = setInterval(async function() {
            if ( autoplay.value === false ) {
                buffer.value = buffer.value * 1;
            }
            else {
                buffer.value = buffer.value - 1000;
            }
            currentBuffer.value = buffer.value / 15000;
        }, 1000);
      }
    });

    return {
      slide,
      minCount,
      collectionSlide,
      autoplay,
      imgColor,
      bannerPhotos,
      transactionsIndex,
      chunkedTransactions,
      showFocusedNFT,
      focusedFrontImage,
      focusedBackImage,
      cardSize,
      transactionsTotalCount,
      displayedNFTCount,
      lightMode,
      chunkSize,
      currentBuffer,
      buffer,
      animationSpeed,
      beforeResetBuffer,
      resetBuffer,
      checkIsVideo
    }
  },
  components: {
    DataCard,
    FocusedNFTDialog
  },
}
</script>

<style lang="scss">
.custom-caption {
    text-align: center;
    padding: 12px;
    color: white;
    background-color: rgba(0, 0, 0, .3);
}

.q-carousel__navigation {
    .q-btn {
        font-size: 8px !important;
    }
}

.q-carousel__slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.q-carousel__slide {
    // background-position: 50%;
}
.top-image-card {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
</style>
