import { createStore } from 'vuex';
import routerStore from './routerStore';
import transactionsStore from './transactionsStore';
import focusedNFTStore from './focusedNFTStore';
import configStore from './configStore';

const store = createStore({
    modules: {
        configStore: configStore,
        routerStore: routerStore,
        transactionsStore: transactionsStore,
        focusedNFTStore: focusedNFTStore,
    }
});

export default store;