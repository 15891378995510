const focusedNFTStore = {
    namespaced: true,
    state: () => ({
        isShowing: false,
        frontImage: '',
        backImage: '',
    }),
    mutations: {
        setIsShowing(state, show) {
          state.isShowing = show;
        },
        setFrontImage(state, image) {
          state.frontImage = image;
        },
        setBackImage(state, image) {
          state.backImage = image;
        },
    },
    actions: {
    },
    getters: {
        getIsShowing(state) {
            return state.isShowing;
        },
        getFrontImage(state) {
            return state.frontImage;
        },
        getBackImage(state) {
            return state.backImage;
        },
    }  
}

export default focusedNFTStore;