const configStore = {
    namespaced: true,
    state: () => ({
        lightMode: localStorage.getItem('lightMode')  ? JSON.parse(localStorage.getItem('lightMode')) : true,
    }),
    mutations: {
        setLightMode(state, lightMode) {
            state.lightMode = lightMode;
            localStorage.setItem('lightMode', lightMode);
        },
    },
    actions: {
        toggleLightMode(context) {
            context.commit('setLightMode', ! context.getters.getLightMode);
        },
    },
    getters: {
        getLightMode(state) {
            return state.lightMode;
        },
    },
}

export default configStore;