<template>
    <div>
        <q-dialog
            v-model="dialog"
            persistent
            maximized
            transition-show="slide-up"
            transition-hide="slide-down"
        >
            <q-card class="bg-text text-white flex justify-center items-center" style="overflow: hidden; height: 100%;">
                <div class="background-image justify-center" :style="`background-image: url(${backImage}); height: calc( 100% + 220px ); width: calc( 100vw + 220px ); background-size: cover; position: absolute; filter: blur(75px); margin: -110px -110px -110px -110px; background-position: 50% 50%;`"/>
                <div class="foreground-image flex items-center" :style="`max-height: calc( 80% - 30px ); max-width: 650px; width: calc( 50% ); position: absolute; background-position: 50% 50%;`">
                    <q-img v-if="! checkIsVideo(frontImage)" :ratio="1" img-class="top-image-card" :style="`border-radius: 10px; width: 100%; background-color: transparent !important;`" :src="frontImage" fit="contain">
                        <template v-slot:error>
                            <div class="absolute-full flex column flex-center text-warning non-selectable" :style="`background-color: ${dark ? '' : '#FFF'} !important;`">
                                <q-icon size="48px" class="text-warning" name="error"/>
                                <div>Error Loading Image</div>
                            </div>
                        </template>
                    </q-img>
                    <video v-else :img-class="`bg-card-primary${dark ? '-dark' : ''}`" style="border-radius: 10px; object-fit: cover; max-height: 100%; max-width: 100%; z-index: 5;" :src="frontImage" autoplay loop muted :controls="false"/>
                </div>
                <q-btn round dense flat icon="close" @click="close" style="right: 8px; top: 8px; position: absolute; background-color: rgba( 0, 0, 0, .6 )">
                    <q-tooltip class="bg-white text-primary">Close</q-tooltip>
                </q-btn>
            </q-card>
        </q-dialog>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'FocusedNFTDialog',
  props: {
    frontImage: String,
    backImage: String,
  },
  setup () {
    const store = useStore(), 
        dialog = ref(true);

    const checkIsVideo = (string) => {
        if (string) {
            return string.includes(".mp4");
        }
        return false
    }

    const close = async () => {
        await store.commit('focusedNFTStore/setIsShowing', false);
        await store.commit('focusedNFTStore/setFrontImage', '');
        await store.commit('focusedNFTStore/setBackImage', '');
    }

    return {
        close,
        checkIsVideo,
        dialog,
    }
  },
  components: {
  },
}
</script>