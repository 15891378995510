const collectionStore = {
    namespaced: true,
    state: () => ({
        transactions: [],
        transactionsIndex: 0,
        featured: [],
        currentPage: 1,
        totalPages: 1,
        totalCount: 0,
        transactionsCount: 0,
        limit: 50,
    }),
    mutations: {
        setTransactions(state, transactions) {
          state.transactions = transactions;
        },
        setFeatured(state, featured) {
          state.featured = featured;
        },
        setTotalPages(state, pages) {
          state.totalPages = parseInt(pages);
        },
        setTotalCount(state, count) {
          state.totalCount = parseInt(count);
        },
        setTransactionsCount(state, count) {
          state.transactionsCount = parseInt(count);
        },
        setCurrentPage(state, page) {
          state.currentPage = parseInt(page);
        },
        bumpTransactionsIndex(state) {
          state.transactionsIndex = state.transactionsIndex + 1;
        }
    },
    actions: {
        async getTransactions(context, data) {
          let transactionsURL = `${ ! process.env.VUE_APP_LOCAL ? '' : `${process.env.VUE_APP_URL_PREFIX}`}/transactions?page=${data && data.page ? data.page : 1}&limit=${context.state.limit}&query=${data && data.query ? data.query : ''}&address=${data && data.address ? data.address : ''}`;

          await fetch(transactionsURL, {
            method: 'get',
            headers: { 'Content-type': 'application/json' },
          }).then( async (response) => {
            if (!response.ok) {
              throw Error(response.statusText);
            }

            let data = await response.json()
            
            context.commit('setTransactions', data.transactions || []);
            // context.commit('setTotalPages', Math.ceil(data.totalPages) || 1);
            // context.commit('setTotalCount', data.totalCount || 0);
            context.commit('setTransactionsCount', data.transactionsCount || 0);
            context.commit('setCurrentPage', data.currentPage || 0);
            context.commit('bumpTransactionsIndex');
          }).catch((error) => {
            console.log('Looks like there was a problem: \n', error);
          });
        },
        async getTransactionsTotalCount(context, data) {
          let transactionsURL = `${ ! process.env.VUE_APP_LOCAL ? '' : `${process.env.VUE_APP_URL_PREFIX}`}/transactions_total_count?limit=${context.state.limit}&query=${data && data.query ? data.query : ''}&address=${data && data.address ? data.address : ''}`;

          await fetch(transactionsURL, {
            method: 'get',
            headers: { 'Content-type': 'application/json' },
          }).then( async (response) => {
            if (!response.ok) {
              throw Error(response.statusText);
            }

            let data = await response.json()
            
            context.commit('setTotalCount', data.totalCount || 0);
            context.commit('setTotalPages', Math.ceil(data.totalPages) || 1);
            context.commit('bumpTransactionsIndex');
          }).catch((error) => {
            console.log('Looks like there was a problem: \n', error);
          });
        },
        async getFeatured(context) {
            await fetch(`${ ! process.env.VUE_APP_LOCAL ? '' : `${process.env.VUE_APP_URL_PREFIX}`}/featured`, {
              method: 'get',
              headers: { 'Content-type': 'application/json' },
            }).then( async (response) => {
              if (!response.ok) {
                throw Error(response.statusText);
              }

              let data = await response.json()
              
              context.commit('setFeatured', data || []);
            }).catch((error) => {
              console.log('Looks like there was a problem: \n', error);
            });
        }
    },
    getters: {
       getTransactions(state) {
        return state.transactions;
       },
       getFeatured(state) {
        return state.featured;
       },
       getTotalPages(state) {
        return state.totalPages;
       },
       getTotalCount(state) {
        return state.totalCount;
       },
       getTransactionsCount(state) {
        return state.transactionsCount;
       },
       getCurrentPage(state) {
        return state.currentPage;
       },
       getLimit(state) {
        return state.limit;
       },
       getTransactionsIndex(state) {
         return state.transactionsIndex
       }
    }  
}

export default collectionStore;