import { createApp } from 'vue';
import App from './App.vue';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import store from './store';
import router from './router';

const app = createApp(App);

app.use(store);

app.use(router);

app.use(Quasar, quasarUserOptions).mount('#app');
