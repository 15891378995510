import { useRouter } from '../router/index';

const routerStore = {
    namespaced: true,
    state: () => ({
    }),
    mutations: {
    },
    actions: {
        async routerPush(route) {
            const router = useRouter(),
                wallet = router.currentRoute.value.query.wallet,
                query = {};
            //     currentPage = router.currentRoute.value.query.page,
            //     pageLimit = router.currentRoute.value.query.limit,
            
            query.wallet = wallet;
            // query.page = route.query && route.query.page ? route.query.page : currentPage || 1;
            // query.limit = pageLimit || 50;
            // route.query = query
            
            await router.push(route)
        },
        async addWalletQuery(context,data) {
            const router = useRouter(),
                query = {};
            
            query.wallet = data.wallet
            
            await router.replace({name: router.currentRoute.value.name, query: query})
        },
    },
    getters: {
    }  
}

export default routerStore;