<template>
  <q-layout view="hHh Lpr lff">
    <q-header dense :class="`text-text row`" :style="`min-height: 40px; background-color: ${headerColor};`">
      <span style="font-size: 16px; height: 20px; margin-left: 8px; padding: 2px 6px; background-color: #FE5F55; border-radius: 6px; line-height: 16px;" @click="goToHome" class="cursor-pointer self-center">bp</span>
      <q-space/>
      <q-btn v-show="! showSearch" size="11px" flat round dense :style="`height: 20px; background-color: ${buttonColor}; margin-right: 8px; margin-top: 6px;`" @click="clickSearch">
        <q-icon size="14px" :color="lightMode ? 'primary' : 'secondary'" name="fas fa-search" class="self-center"/>
        <q-tooltip :class="`bg-${ lightMode ? 'white' : 'black'} text-${ lightMode ? 'primary' : 'text'}`">Search for Eth Wallet</q-tooltip>
      </q-btn>
      <q-input :dark="lightMode" class="dark" autofocus ref="walletInput" v-if="showSearch" standout dense style="margin-top: 5px; margin-right: 8px;" @blur="closeSearch" @focus="searchActive" v-model="walletSearch">
        <template v-slot:prepend>
          <q-icon size="16px" :color="lightMode ? 'primary' : 'secondary'" name="fas fa-search" class="self-center"/>
        </template>
      </q-input>
      <q-btn size="11px" flat round dense :style="`height: 15px; background-color: ${buttonColor}; margin-right: 8px; margin-top: 6px;`" @click="clickToolbox">
        <q-icon size="14px" :color="lightMode ? 'primary' : 'secondary'" name="fas fa-toolbox" class="self-center"/>
        <q-menu fit anchor="bottom left" self="top right" :class="`text-text${ lightMode ? '-dark' : ''}`" :style="`background-color: ${lightMode ? 'rgba(255, 255, 255, 0.6)' : 'rgba(1, 15, 20, 0.6)'};`">
          <q-list style="min-width: 200px">
            <div class="q-px-sm text-overline self-center">Bozp Tooling</div>
            <q-separator :dark="!lightMode" />
            <q-item clickable v-close-popup class="q-py-xs q-px-md flex row justify-center" style="max-height: 30px;" @click="openApp('https://nftwatcher.xyz')">
              <q-img class="col-4 justify-center self-center" src="./assets/nft-watcher-logo.svg" :img-style="{ 'max-height': '20px', 'max-width': '30px', 'margin-top': '8px' }"/>
               <!-- style="max-height: 20px; max-width: 30px;" -->
              <q-item-section class="col-8 justify-end" >nft watcher</q-item-section>
            </q-item>
            <q-item clickable v-close-popup class="q-py-xs q-px-md flex row justify-center" style="max-height: 30px;" @click="openApp('https://rarity.bozp.dev')">
              <q-img class="col-4 justify-center self-center" src="./assets/open-rarity-path.svg" :img-style="{ 'max-height': '20px', 'max-width': '30px', 'margin-top': '8px' }"/>
               <!-- style="max-height: 20px; max-width: 30px;" -->
              <q-item-section class="col-8 justify-end" >Open Rarity</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
      <span :style="`font-size: 16px; height: 24px; padding: 6px 10px; background-color: ${buttonColor}; border-radius: 6px; line-height: 12px; margin-right: 8px; color: ${ lightMode ? '#212121' : '#d8d8d8'}`" class="cursor-pointer self-center">bozp.eth</span>
      <q-toggle
        :model-value="lightMode"
        dense
        checked-icon="fas fa-sun"
        color="yellow"
        unchecked-icon="fas fa-moon"
        size="40px"
        class="q-pa-none"
        style="padding-right: 8px;"
        @update:model-value="toggleMode"
      >
        <q-tooltip :class="`bg-${ lightMode ? 'white' : 'black'} text-${ lightMode ? 'primary' : 'text'}`">{{lightMode ? "Toggle for dark mode" : "Toggle for light mode"}}</q-tooltip>
      </q-toggle>
      <!-- <q-btn flat round dense>
        <q-icon name="fab fa-twitter" class="self-center"/>
      </q-btn> -->
      <!-- <q-separator inset spaced dark/> -->
    </q-header>
    <q-page-container style="padding-top: 0px;">
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';


export default {
  name: 'App',
  setup () {
    const store = useStore(),
      showSearch = ref(false),
      walletSearch = ref(''),
      walletInput = ref(),
      lightMode = computed(() => {
        return store.getters["configStore/getLightMode"]
      }),
      headerColor = computed(() => lightMode.value ? 'rgba(255, 255, 255, 0.4)' : 'rgba(1, 15, 20, 0.4)'),
      buttonColor = computed(() => lightMode.value ? 'rgba(255, 255, 255, 0.4)' : 'rgba(1, 15, 20, 0.75)');

    const goToHome = async () => {
      await store.dispatch("routerStore/routerPush", {name:'home'});
      await store.dispatch('transactionsStore/getTransactions');
      await store.dispatch('transactionsStore/getTransactionsTotalCount');
    };

    const openApp = async (appUrl) => {
      window.open(appUrl, '_blank').focus();
    };

    const toggleMode =  () => {
      store.dispatch("configStore/toggleLightMode");
    };

    const searchActive = async () => {
      if ( walletInput.value ) {
        const el = walletInput.value.getNativeElement();

        el.addEventListener("keydown", async function(event) {
          if (event.key === "Enter") {
            await store.dispatch('routerStore/addWalletQuery', {wallet: walletSearch.value});
            await store.dispatch('transactionsStore/getTransactions', {address: walletSearch.value});
            await store.dispatch('transactionsStore/getTransactionsTotalCount', {address: walletSearch.value});
          }
        });
      }
    };

    const clickSearch = () => {
      showSearch.value = true;
    };

    const closeSearch = async () => {
      if ( walletInput.value ) {
        const el = walletInput.value.getNativeElement();

        el.removeEventListener("keydown", () => {});
      }

      if ( walletSearch.value.length > 0 ) {
        await store.dispatch('routerStore/addWalletQuery', {wallet: walletSearch.value});
        await store.dispatch('transactionsStore/getTransactions', {address: walletSearch.value});
        await store.dispatch('transactionsStore/getTransactionsTotalCount', {address: walletSearch.value});
      }

      showSearch.value = false;
    };

    onMounted( async () => {
      // await store.dispatch('profileStore/getProfile');
    });

    return {
      goToHome,
      showSearch,
      searchActive,
      clickSearch,
      closeSearch,
      walletSearch,
      walletInput,
      lightMode,
      toggleMode,
      headerColor,
      buttonColor,
      openApp
    }
  },
  components: {
  },
}
</script>

<style>
.q-field--outlined .q-field__control:before {
  /* border: 2px solid #E1E5F2 !important; */
}
.q-field__label {
    /* color: #E1E5F2 !important; */
    margin-top: -5px;
    font-size: 10px !important;
    line-height: 10px !important;
}
.q-field__native {
    /* color: #E1E5F2 !important; */
    min-height: 28px !important;
    line-height: 14px !important;
    padding-top: 10px !important;
    font-size: 12px !important;
}
.q-field__append {
    /* color: #E1E5F2 !important; */
}
.q-field--dense {
    height: 28px;
}
.q-field__control {
  padding-left: 8px !important;
  background-color: rgba(255, 255, 255, 0.45) !important;
  border-radius: 6px !important;
  height: 28px !important;
  min-height: 28px !important;
}
.q-field--labeled {
    padding-top: 0px !important;
}
.q-field__append {
  height: 28px !important;
}

.q-field__marginal {
  max-height: 28px;
}
/* .q-item {
    padding: 4px 8px !important;
    min-height: 32px !important;
    border-radius: 4px;
} */
.active-attribute {
  box-shadow: 0 0 0 1px #F6E9A2 !important;
  background-color: #768273 !important;
}
</style>
