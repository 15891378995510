import { render } from "./DataCard.vue?vue&type=template&id=091aceaa&scoped=true"
import script from "./DataCard.vue?vue&type=script&lang=js"
export * from "./DataCard.vue?vue&type=script&lang=js"

import "./DataCard.vue?vue&type=style&index=0&id=091aceaa&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-091aceaa"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QList from 'quasar/src/components/item/QList.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QList,QImg,QIcon,QItem});
