<template>
    <div :style="`width: ${width}`">
        <q-card flat bordered :class="`${! onlyCard ? 'readonly-' : ''}data-card bg-card-primary${dark ? '-dark' : ''} self-center`" :style="`border-color: ${dark ? '#333333' : '#E1E5F2'}; border-radius: 12px; border-width: 2px;`" @click="dataCardClicked">
            <q-card-section key="description" :class="`q-pa-none ${onlyCard ? 'cursor-pointer' : ''}`">
                <q-list class="q-pa-none">
                    <q-card-section class="col-12 q-pa-none" :style="`border-bottom: 2px solid ${dark ? '#333333' : '#E1E5F2'};`">
                        <q-img v-if="! isVideo" :ratio="1" img-class="data-card__image" :style="`border-radius: 10px 10px 0px 0px; background-color: ${dark ? '' : '#FFF'} !important;`" :src="dataImage" fit="contain">
                            <template v-slot:error>
                                <div class="absolute-full flex column flex-center text-warning non-selectable" :style="`background-color: ${dark ? '' : '#FFF'} !important;`">
                                    <q-icon size="48px" class="text-warning" name="error"/>
                                    <div>Error Loading Image</div>
                                </div>
                            </template>
                        </q-img>
                        <div v-else style="width: 100%; height: 100%; aspect-ratio: 1 / 1;">
                            <video :img-class="`bg-card-primary${dark ? '-dark' : ''}`" style="border-radius: 10px 10px 0px 0px; object-fit: contain; max-width: 100%; max-height: 100%; min-width: 100%; min-height: 100%; margin-bottom: -7px;" :src="dataImage" @click="dataCardClicked" autoplay loop muted/>
                        </div>
                    </q-card-section>
                    <div :class="`row q-px-none q-pt-none q-pb-none justify-between text-${ dark ? 'secondary' : 'primary'}`" style="height: 25px;">
                        <div key="name" class="q-px-sm q-pb-sm q-pt-none row justify-between text-no-wrap text-caption" style="width: 100%;">
                            <div :class="`${dataType === 'project' ? 'col-12' : 'col-12'}`" v-if="onlyCard && data.name">{{data.name}}</div>
                            <div :class="`${dataType === 'project' ? 'col-12' : 'col-12'}`" v-else-if="data.name">{{data.name}}</div>
                        </div>
                    </div>
                    <q-item v-if="!onlyCard && data.description" class="row justify-center">
                        <div class="q-pa-sm text-info row">{{data.description}}</div>
                    </q-item>
                    <q-item v-if="!onlyCard" class="q-pt-none row justify-center">
                        <div class="q-py-xs q-px-sm text-h6 bg-secondary text-info row" style="border-radius: 12px;"><div class="text-accent text-h6">Rarity Score:</div>&nbsp;{{dataRarityScore}}</div>
                    </q-item>
                </q-list>
            </q-card-section>
        </q-card>
    </div>
</template>

<style scoped>
.data-card:hover {
    border-color: #FE5F55 !important;
    border-width: 2px !important;
}
.rarity-toggle {
  border: 2px solid #FE5F55
}
</style>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'DataCard',
  props: {
    data: Object,
    dataType: String,
    onlyCard: Boolean,
    width: {
        type: String,
        default: '250px'
    },
    dark: Boolean,
  },
  setup ( props) {
    const store = useStore(),
        dataImage = ref( props.data.images ? ( props.data.images.animationUrl && props.data.images.animationUrl.includes(".mp4") ? props.data.images.animationUrl : props.data.images.url ) : '' ),
        dataId = ref(props.data.tokenID),
        totalCount = computed(() => store.getters["transactionsStore/getTotalCount"]),
        isVideo = computed(() => {
            return dataImage.value ? dataImage.value.includes(".mp4") : false;
        });

    const dataCardClicked = async () => {
        await store.commit('focusedNFTStore/setIsShowing', true);
        await store.commit('focusedNFTStore/setFrontImage', props.data.images.animationUrl || props.data.images.url);
        await store.commit('focusedNFTStore/setBackImage', props.data.images.previewUrl);
    };

    return {
        dataImage,
        isVideo,
        dataId,
        dataCardClicked,
        totalCount,
    }
  },
  components: {
  },
}
</script>