import { render } from "./FocusedNFTDialog.vue?vue&type=template&id=4e5dc808"
import script from "./FocusedNFTDialog.vue?vue&type=script&lang=js"
export * from "./FocusedNFTDialog.vue?vue&type=script&lang=js"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QImg,QIcon,QBtn,QTooltip});
